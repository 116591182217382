import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import AddSheets from "../pages/AddSheets";
import ConcatenateSheets from "../pages/ConcatenateSheets";
import { SheetType } from "../types";
import { useMetaData } from "../hooks/useMetaData";

const fullFeatureRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    element: <ConcatenateSheets></ConcatenateSheets>,
    path: `${SheetType.concat}`,
  },
  {
    element: <AddSheets />,
    path: "/:sheetType/:sheetName",
  },
];

const concatOnlyRoute = [
  {
    index: true,
    element: <Navigate to={`${SheetType.concat}`} />,
  },
  {
    element: <ConcatenateSheets></ConcatenateSheets>,
    path: `${SheetType.concat}`,
  },
  {
    element: <AddSheets />,
    path: "/:sheetType/:sheetName",
  },
];

export default function AppRoutes() {
  const { data, isLoading } = useMetaData();

  if (isLoading || !data) {
    return <></>;
  }

  const routes = data.computeSheetEnabled ? fullFeatureRoutes : concatOnlyRoute;

  return (
    <Routes>
      {routes.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}
    </Routes>
  );
}
