import React, { useState, useCallback } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { SheetType } from "../types";
import { useMetaData } from "../hooks/useMetaData";

const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);

  const { data, isLoading } = useMetaData();

  const toggleNavbar = useCallback(() => {
    setCollapsed((prevState) => !prevState);
  }, []);

  if (isLoading || !data) {
    return <div>Loading...</div>;
  }

  const { computeSheetEnabled, computeSheetName, appTitle } = data;

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        container
        light
      >
        <NavbarBrand tag={Link} to="/">
          {appTitle}
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse
          className="d-sm-inline-flex flex-sm-row-reverse"
          isOpen={!collapsed}
          navbar
        >
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} className="text-dark" to="/">
                Home
              </NavLink>
            </NavItem>
            {computeSheetEnabled && (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark"
                    to={`/${SheetType.compute}/${computeSheetName}`}
                  >
                    Compute Sheets
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark"
                    to={`${SheetType.concat}`}
                  >
                    Concatenate Sheets
                  </NavLink>
                </NavItem>
              </>
            )}
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default NavMenu;
